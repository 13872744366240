const sendSupportForm = async (contact, comment, file, uid) => {
  const result = { ok: false, data: null };

  const formData = new FormData();
  formData.append('email', contact);
  formData.append('text', comment);
  formData.append('file', file);
  formData.append('uid', Number(uid));

  try {
    await fetch('/upload.php', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          result.ok = true;
          result.data = data;
        } else {
          result.ok = false;
          result.error = data.message;
        }
      });
  } catch (err) {
    result.ok = false;
    result.error = err.message;
  }

  return result;
};

export default sendSupportForm;
