<template>
  <div class="payform-status">
    <IconFail></IconFail>
    <p>
      Время действия заявки вышло. Если вы не успели выполнить перевод, создайте новую заявку.
    </p>
    <div class="payform__btn-wrapper">
      <a class="payform__btn" :href="returnUrl">
        <span>Вернуться на сайт</span>
      </a>
    </div>
  </div>
</template>

<script>
import IconFail from './IconFail.vue';

export default {
  name: 'OrderError',
  props: {
    returnUrl: String,
  },
  components: {
    IconFail,
  },
};
</script>
