<template>
  <div class="req-field">
    <h5 class="req-field__caption">{{ caption }}</h5>
    <div class="req-field__row">
      <span class="req-field__content">{{ content }}</span>
      <button class="req-field__copy-btn copy-btn" @click="trimCurrenyAndCopy()">Copy</button>
      <transition name="copied-fade">
        <div v-if="copied" class="copied">Скопировано!</div>
      </transition>
    </div>
  </div>
</template>

<script>
import { useClipboard } from '@vueuse/core';

export default {
  name: 'ReqField',
  setup(props) {
    const { copy, copied } = useClipboard({ content: props.content });

    return {
      copy,
      copied,
    };
  },
  props: {
    caption: String,
    content: String,
  },
  methods: {
    trimCurrenyAndCopy() {
      const pure = this.$props.content.split(' ')[0];
      this.copy(pure);
    },
  },
};
</script>

<style scoped lang="sass">
@import '../assets/sass/mixin-reset-btn'

h1, h2, h3, h4, h5, h6
  margin: 0px

.req-field
  text-align: left

.req-field__caption
  font-size: 15px
  font-weight: 400
  color: $text-color-second

  @media screen and (max-width: 469px)
    font-size: 14px

.req-field__row
  position: relative
  display: flex
  margin: 7px 0px 0px
  box-sizing: border-box
  padding: 8px 12px
  background: #071623
  border-radius: 6px

  &::after
    content: ''
    position: absolute
    right: 40px
    top: 0px
    bottom: 0px
    height: 100%
    width: 60px
    background: linear-gradient(271deg, #071623, transparent)

.req-field__content
  position: relative
  display: block
  width: 100%
  font-size: 18px
  font-weight: 400
  max-width: 380px
  overflow-x: scroll
  -webkit-user-drag: none
  scrollbar-width: none
  box-sizing: border-box
  padding: 0px 15px 0px 0px
  color: white
  letter-spacing: 1px

  &::-webkit-scrollbar
    display: none

  @media screen and (max-width: 820px)
    max-width: 300px

  @media screen and (max-width: 469px)
    max-width: 240px

.req-field__copy-btn
  @include reset-btn

  position: absolute
  top: 50%
  right: 10px
  transform: translateY(-50%)
  background: url('~@/assets/images/icon-copy.png') no-repeat center / 100%
  font-size: 0px
  display: block
  width: 20px
  height: 20px
  cursor: pointer
  opacity: 0.5
  transition: opacity 300ms linear

  &:hover
    opacity: 1

.copied
  position: absolute
  right: 0px
  top: -30px
  font-size: 12px
  box-sizing: border-box
  padding: 5px 6px
  background: #06121cd9
  z-index: 1
  border-radius: 6px

.copied-fade-enter-active,
.copied-fade-leave-active
  transition: opacity 0.5s ease

.copied-fade-enter-from,
.copied-fade-leave-to
  opacity: 0
</style>
