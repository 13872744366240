<template>
  <div v-if="isFetched" class="payform">
    <template v-if="orderInfo.returnUrl">
      <PayFormHeader :title="headerTitle" :time="restTime"></PayFormHeader>
      <PayFormContent
        :orderInfo="orderInfo"
      ></PayFormContent>
      <div class="payform-footer">
        <RouterLink :to="supportLink">
          <FooterLeftButton
            v-if="orderInfo.status !== 3"
            text="Поддержка клиента"
          ></FooterLeftButton>
        </RouterLink>
      </div>
      <PaymentHints
        v-if="orderInfo.status === 1"
      ></PaymentHints>
    </template>
    <p v-else-if="haveRequestError" align="center">
      😵 Не удалось найти заявку по указанным параметрам. Проверьте пожалуйста введенные параметры.
    </p>
    <p v-else align="center">
      👾 Форма оплаты не доступна для текущего партнера.
    </p>
  </div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';
import { useRoute } from 'vue-router';
import PayFormHeader from '@/components/PayFormHeader.vue';
import PayFormContent from '@/components/PayFormContent.vue';
import PaymentHints from '@/components/PaymentHints.vue';
import FooterLeftButton from '@/components/FooterLeftButton.vue';
import fetchOrder from '@/api/fetchOrder';
import useOrderCreateAtFormatter from '@/composables/useOrderCreateAtFormatter';

export default {
  name: 'MainView',
  setup() {
    const orderInfo = ref({});
    const restTime = ref('--:--');
    const isFetched = ref(false);
    const haveRequestError = ref(false);
    let orderTimerId = null;
    let refetchTimerId = null;

    const getOrderInfo = async () => {
      orderInfo.value = await fetchOrder();

      if (!orderInfo.value) {
        haveRequestError.value = true;
      }

      isFetched.value = true;
    };

    onMounted(() => {
      getOrderInfo();

      refetchTimerId = setInterval(() => {
        getOrderInfo();
      }, 8000);
    });

    onUnmounted(() => {
      clearInterval(refetchTimerId);
      clearTimeout(orderTimerId);
    });

    watch(orderInfo, () => {
      clearTimeout(orderTimerId);

      if (orderInfo.value) {
        orderTimerId = setTimeout(function repeat() {
          const createdAtMilliseconds = Number(new Date(orderInfo.value.createdAt.date.split('.')[0]));
          const time = useOrderCreateAtFormatter(createdAtMilliseconds, orderInfo.value.ttl);
          restTime.value = `${time.minutes}:${time.seconds}`;

          orderTimerId = setTimeout(repeat, 1000);
        }, 0);
      }
    });

    return {
      orderInfo,
      restTime,
      getOrderInfo,
      isFetched,
      haveRequestError,
    };
  },
  components: {
    PayFormHeader,
    PayFormContent,
    PaymentHints,
    FooterLeftButton,
  },
  computed: {
    headerTitle() {
      let result = '';

      switch (this.orderInfo.status) {
        case 3:
          result = 'Перевод выполнен';
          break;
        case 4:
        case 5:
          result = 'Время платежа вышло';
          break;
        default:
          result = 'Инструкция оплаты';
      }

      return result;
    },
    supportLink() {
      const route = useRoute();

      return `/support/${route.params.id}/${route.params.secret}/`;
    },
  },
};
</script>
