<template>
  <div class="payform-status">
    <IconSuccess></IconSuccess>
    <p>
      Оплата прошла успешно.
    </p>
    <div class="payform__btn-wrapper">
      <a class="payform__btn" :href="returnUrl">
        <span>Вернуться на сайт</span>
      </a>
    </div>
  </div>
</template>

<script>
import IconSuccess from './IconSuccess.vue';

export default {
  name: 'OrderSuccess',
  props: {
    returnUrl: String,
  },
  components: {
    IconSuccess,
  },
};
</script>
