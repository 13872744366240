import { createWebHistory, createRouter } from 'vue-router';

import MainView from './views/MainView.vue';
import SupportView from './views/SupportView.vue';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: MainView,
    meta: {
      title: 'Оплата платежа',
    },
  },
  {
    path: '/:id/:secret',
    component: MainView,
    meta: {
      title: 'Оплата платежа',
    },
  },
  {
    path: '/support/:id/:secret',
    component: SupportView,
    meta: {
      title: 'Помощь с платежом',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} №${to.params.id}` || 'Оплата платежа';
  next();
});

export default router;
