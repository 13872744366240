<template>
<div>
  <svg
    class="fail-icon"
    xmlns="http://www.w3.org/2000/svg"
    height="140"
    width="140"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <circle
      class="circle"
      fill="#5bb543"
      cx="24"
      cy="24"
      r="22"
    />
    <path
      class="tick"
      fill="none"
      stroke="#FFF"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="M16 16 L 32 32"
    />
    <path
      class="tick"
      fill="none"
      stroke="#FFF"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="M16 32 L 32 16"
    />
  </svg>
</div>
</template>

<script>
export default {
  name: 'IconFail',
};
</script>

<style scoped lang="sass">
@import '../assets/sass/fail-icon'
</style>
