<template>
<div>
  <svg
    class="success-icon"
    xmlns="http://www.w3.org/2000/svg"
    height="140"
    width="140"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22"/>
    <path
      class="tick"
      fill="none"
      stroke="#FFF"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="M14 27 l 5.917 4.917 L 34 17"
    />
  </svg>
</div>
</template>

<script>
export default {
  name: 'OrderSuccess',
};
</script>

<style scoped lang="sass">
@supports (animation: success-icon-grow .5s cubic-bezier(.25, .25, .25, 1) forwards)
  .success-icon
    .tick
      stroke-opacity: 0
      stroke-dasharray: 29px
      stroke-dashoffset: 29px
      animation: success-icon-draw .5s cubic-bezier(.25, .25, .25, 1) forwards
      animation-delay: .6s

    .circle
      fill-opacity: 0
      stroke: #54ea8e
      stroke-width: 16px
      transform-origin: center
      transform: scale(0)
      animation: success-icon-grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards

@keyframes success-icon-grow
  60%
    transform: scale(.8)
    stroke-width: 4px
    fill-opacity: 0

  100%
    transform: scale(.9)
    stroke-width: 2px
    fill-opacity: 0.1
    fill: #54ea8e

@keyframes success-icon-draw
  0%,
  100%
    stroke-opacity: 1

  100%
    stroke-dashoffset: 0
</style>
