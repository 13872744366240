<template>
  <div class="payment-hints">
    <ul class="payment-hints__list">
      <li class="payment-hints__item">
        <span>
          Вы должны перевести ровно указанную сумму (не больше и не меньше),
          иначе ваш платеж зачислен не будет!
          При переводе не точной суммы вы
          можете оплатить чужой заказ и
          потерять средства.
        </span>
      </li>
      <li class="payment-hints__item">
        <span>
          Делайте перевод одним платежом, если
          вы разобьете платеж на несколько, ваш платеж зачислен не будет!
        </span>
      </li>
      <li class="payment-hints__item">
        <span>
          Перевод нужно осуществить в течении 20 мин. после создания заказа.
          Если вам не хватает времени, отмените заявку и создайте новую!
        </span>
      </li>
      <li class="payment-hints__item">
        <span>
          Если у вас возникли какие-либо проблемы с оплатой, обратитесь в поддержку.
          Проблемы с оплатой рассматриваются в течении 48 часов.
        </span>
      </li>
      <li class="payment-hints__item">
        <span>
          После оплаты, нажмите кнопку "Я перевел" и подождите 5-10 минут,
          наша система проверит ваш платеж.
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PaymentHints',
};
</script>

<style scoped lang="sass">
.payment-hints
  margin: 26px 0px 30px
  background: #0b1b28
  padding: 20px
  border-radius: 6px
  font-size: 15px

  @media screen and (max-width: 469px)
    padding: 15px

.payment-hints__list
  padding: 0px
  margin: 0px
  list-style: none
  line-height: 1.36
  letter-spacing: 0.3px
  counter-reset: hints

.payment-hints__item
  display: flex
  align-items: center
  counter-increment: hints
  padding: 9px 5px
  margin-bottom: 12px
  border-bottom: 1px solid #172b3f

  &::before
    content: counter(hints)
    display: block
    margin-top: -6px
    margin-right: 20px
    font-size: 20px
    color: #56d588

  &:last-child
    margin-bottom: 0px
    border: none
</style>
