<template>
  <div class="payform">
    <PayFormHeader title="Поддержка клиента" time=""></PayFormHeader>
    <div class="payform-content">
      <h2 class="payform-content__title">
        Помощь с платежом:
        <span class="color-brand"><span>№</span> {{ orderId }}</span>
      </h2>
      <div class="payform-content__inner">
        <p>
          Если вы допустили ошибку при переводе, отправьте
          нам чек оплаты и оставьте контакт для связи.
          В случае, если вы не совершили перевод,
          а время платежа вышло &mdash; просто создайте новую заявку.
        </p>
        <SupportForm></SupportForm>
      </div>
    </div>
    <div class="payform-footer">
      <RouterLink :to="`/${route.params.id}/${route.params.secret}`">
        <FooterLeftButton text="Вернуться к реквизитам"></FooterLeftButton>
      </RouterLink>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import PayFormHeader from '@/components/PayFormHeader.vue';
import SupportForm from '@/components/SupportForm.vue';
import FooterLeftButton from '@/components/FooterLeftButton.vue';

const route = useRoute();
const orderId = route.params.id === 'undefined' ? 'Ошибка' : route.params.id;
</script>

<style scoped lang="sass">
@import '../assets/sass/btn'
@import '../assets/sass/payform-content'
</style>
