<template>
  <div class="payform-header">
    <span>{{ title }}</span>
    <span>{{ time }}</span>
  </div>
</template>

<script>
export default {
  name: 'PayFormHeader',
  props: {
    title: String,
    time: String,
  },
};
</script>

<style scoped lang="sass">
.payform-header
  display: flex
  justify-content: space-between
  background: linear-gradient(to right, #5fd99a, #75f7a8, #47c579)
  padding: 18px 20px
  color: #091620
  font-size: 17px
  letter-spacing: 0.5px
  border-radius: 6px 6px 2px 2px
  font-weight: 500
  margin: 0px
  text-align: center

  @media screen and (max-width: 469px)
    padding: 16px 10px
</style>
