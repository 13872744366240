const renameProperty = (target, oldName, name) => {
  const newTarget = { ...target };
  newTarget[name] = newTarget[oldName];
  delete newTarget[oldName];

  return newTarget;
};

const fetchOrder = async () => {
  let result = null;
  const url = new URL(window.location.href);
  const [id, secret] = url.pathname.split('/').slice(1);
  const formData = new FormData();
  formData.set('id', id);
  formData.set('secret', secret);

  const fetchUrl = process.env.NODE_ENV === 'production'
    ? '/send.php'
    : 'https://greengo.cc/api/order/22822188/xtFJKDCx6zfmKYTgp6VeDjl1zC9loSQo';

  const fetchOptions = process.env.NODE_ENV === 'production'
    ? { method: 'POST', body: formData }
    : { method: 'GET' };

  try {
    await fetch(fetchUrl, fetchOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Incorrect URL query parameters.');
        }

        return response.json();
      })
      .then((data) => {
        let newData = { ...data };

        newData = renameProperty(newData, 'order_id', 'orderId');
        newData = renameProperty(newData, 'created_at', 'createdAt');
        newData = renameProperty(newData, 'updated_at', 'updatedAt');
        newData = renameProperty(newData, 'from_amount', 'fromAmount');
        newData = renameProperty(newData, 'bank_name', 'bankName');
        newData = renameProperty(newData, 'return_url', 'returnUrl');
        result = newData;
      })
      .catch((err) => {
        throw new Error(err);
      });
  } catch (err) {
    result = false;
  }

  return result;
};

export default fetchOrder;
